<div class="container-fluid">

    <div class="row animated fadeIn fast" *ngIf="cargando">
        <div class="col-12">

            <div class="alert alert-info text-center" role="alert">
                <h4 class="alert-heading">Cargando...</h4>
                <i class="fa fa-spin fa-refresh fa-2x"></i>
                <p class="mb-0">Por favor ... espere</p>
            </div>

        </div>
    </div>

    <form autocomplete="off" (ngSubmit)="filtrar( form )" #form="ngForm">
        <div class="row animated fadeIn fast">
            <div class="col-sm-8">
                <div class="card">
                    <div class="card-body d-flex">
                        <input  type="text"
                                id="terminoBusqueda"
                                class="form-control"
                                name="filtro"
                                [ngModel] = "filtro"
                                [placeholder]="userHint"
                                #terminoBusqueda
                                (keyup.enter)="buscar( terminoBusqueda.value )">
                        <input type="checkbox" 
                                id="soloRegistrados"
                                name="soloRegistrados"
                                class="form-control"
                                [ngModel]="soloRegistrados"
                                #soloRegistrados="ngModel"/>
                        <label for="soloRegistrados" class="ml-3 w200">{{ 'USERS.onlyRegistered' | translate }}</label>
                        <button type="submit" class="btn btn-secondary btn-block btn-sm ml-2 w80">{{ 'USERS.search' | translate }}</button>
                    </div>
                </div>
            </div>
        </div> 
    </form>

    <div class="row mt-2">
        <div class="col">
            <h6>
                {{ 'USERS.users' | translate }}  ({{ config.totalItems }})
            </h6>
        </div>   
    </div>

    <div class="dataTables_wrapper" *ngIf="!cargando">
        <div class="row">
            <div class="col table-responsive">
                <table class="table table-sm table-striped table-hover mt-2 font-12">
                    <thead class="table-dark ">
                        <tr>
                            <th>id</th>
                            <th></th>
                            <th>Login</th>
                            <th>email</th>
                            <th width="50">Display Name</th>
                            <th>{{ 'USERS.role' | translate }}</th>
                            <th>{{ 'USERS.active' | translate }}</th>
                            <th>{{ 'USERS.creationDate' | translate }}</th>
                            <th>Quota</th>
                            <th>{{ 'USERS.diskSpace' | translate }}</th>
                            <th>Auth Key</th>
                            <th>Live</th>
                            <th>{{ 'USERS.liveFrom' | translate }}</th>
                            <th>{{ 'USERS.liveExpiration' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let usuario of usuarios | paginate: config" [routerLink]="'/usuario/'+usuario.login">
                            <td>{{ usuario.id }}</td>
                            <td><a class="pointer" [routerLink]="['/track', usuario.id]"><img class="user-avatar" [src]="usuario.img | img"/></a></td>
                            <td><a class="pointer" [routerLink]="['/user', usuario.login]">{{ usuario.login }}</a></td>
                            <td>{{ usuario.user_email }}</td>
                            <td>{{ usuario.display_name }}</td>
                            <td>{{ usuario.role }}</td>
                            <td>{{ (!usuario.id || usuario.status === '0') ? 'N' : 'S' }}</td>
                            <td>{{ usuario.created_on | fecha }}</td>
                            <td>{{ usuario.disk_mb_quota }} <i style="color:brown">{{ diskUsage(usuario) | number:'1.1-1' }}%</i></td>
                            <td>{{ usuario.disk_mb_size }}</td>
                            <td>{{ usuario.auth_key }}</td>
                            <td>{{ (!usuario.id || usuario.live_enabled === '0') ? 'N' : 'S' }}</td>
                            <td>{{ usuario.live_start_date | fecha }}</td>
                            <td>{{ usuario.live_expiration | fecha}}</td>
                        </tr>
                    </tbody>
                </table>
                <pagination-controls (pageChange)="cambiaPagina($event)"></pagination-controls>
            </div>
        </div>
        <div class="row">

        </div>
    </div>
</div>