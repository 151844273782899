<div class="container-fluid mt-2 mb-3">
    <div class="row justify-content-center">

        <div class="card" style="width: 30rem;">
            <div class="card-header"><strong>{{ 'FILTER.TITLE' | translate }}</strong></div>
            <div class="card-body">
                <form autocomplete="on" [formGroup]="form" (ngSubmit)="enviar()" (keydown.enter)="enviar()" class="form-horizontal p-t-20">

                    <div *ngIf="trackVisible"  class="form-group row mt-2 ml-2 mr-3">
                        <label for="name" class="col-sm-2 col-form-label">{{ 'FILTER.TRACK' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" formControlName="track" id="track" [placeholder]="trackHint | translate">
                        </div>
                    </div>
                    
                    <div class="form-group row ml-2 mr-3">
                        <label for="name" class="col-sm-2 col-form-label">{{ 'FILTER.NAME' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control" formControlName="name" id="name" [placeholder]="nameHint | translate">
                        </div>
                    </div>

                    <div class="form-group row ml-2">
                        <div class="form-check form-check-inline ml-20">
                            <input class="form-check-input" type="checkbox" id="ch" formControlName="chFlag">
                            <label for="ch" class="form-check-label">{{ 'Campeonatos' | translate }}</label>
                        </div>
                        <div class="checkbox form-check-inline ml-20">
                            <input class="form-check-input" type="checkbox" id="ev" formControlName="evFlag">
                            <label for="ev" class="form-check-label">{{ 'Eventos' | translate }}</label>
                        </div>
                        <div class="form-check form-check-inline ml-20">
                            <input class="form-check-input" type="checkbox" id="rc" formControlName="rcFlag">
                            <label for="rc" class="form-check-label">{{ 'Carerras' | translate }}</label>
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-sm-6 d-flex">
                            <label for="from" class="control-label ml-20 w50">{{ 'FILTER.FROM' | translate }}</label>
                            <input type="date" class="form-control w150 ml-2" formControlName="from" id="from">
                        </div>
                        <div class="form-group col-sm-6 d-flex">
                            <label for="to" class="control-label ml-20 w50">{{ 'FILTER.TO' | translate }}</label>
                            <input type="date" class="form-control w150 ml-2" formControlName="to" id="to">
                        </div>
                    </div>

                    <div class="text-right">
                        <button class="btn btn-outline-secondary btn-sm" type="button" (click)="resetForm()">{{ 'FILTER.RESET' | translate }}</button>
                        <button class="btn btn-secondary btn-sm ml-2 mr-3" type="submit">{{ 'ACTIONS.search' | translate }}</button>
                    </div>

                </form>

            </div>
        </div>
    </div>

</div>    
