<div class="container-fluid animated fadeInLeft mt-5">
    <div class="row justify-content-center align-items-center">
        <form autocomplete="on" [formGroup]="form" (ngSubmit)="login()" class="validate-form">
            <h2>
                Login
            </h2>
            <label class="col-4 mt-2">Usuario</label>
            <div class="m-b-16">
                <div class="col">
                    <input class="form-control" form-control
                            type="text"
                            placeholder="Usuario"
                            formControlName="usuario"
                            autocomplete="usuario"
                            [class.is-invalid]="usuarioNoValido">
                    <small *ngIf="usuarioNoValido" class="text-danger">
                        El campo usuario es obligatorio
                    </small>
                </div>
            </div>

            <label class="col-4 col-form-label mt-2">Contraseña</label>
            <div class="m-b-16">
                <div class="col">
                    <input class="form-control" 
                            type="password"
                            placeholder="Contraseña"
                            autocomplete="new-password"
                            formControlName="pass"
                            [class.is-invalid]="passNoValido">
                    <small *ngIf="passNoValido" class="text-danger">
                        La contraseña debe tener una longitud de 8 posiciones o más
                    </small>
                </div>
            </div>


            <div class="container mt-4">
                <input class="form-control" id="recordar" formControlName="recordar" type="checkbox" name="recordar"/>
                <label for="recordar">
                    Recordar mi usuario
                </label>
            </div>

            <div class="mt-2 ml-3">
                <button class="btn btn-success" type="submit">
                    Iniciar sesión
                </button>
            </div>

        </form>
    </div>
</div>