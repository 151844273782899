import { Component } from '@angular/core';
import { ResultsService } from '../../services/results.service';
import { PublicationTable, PublicationTableItem } from '../../interfaces/publication-table.interface';
import { environment } from 'src/environments/environment';
import { SearchParams } from 'src/app/interfaces/search.interface';
import { AuthService } from 'src/app/adm/services/auth.service';

const itemsPerPage = environment.itemsPerPage;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [
  ]
})
export class HomeComponent  {

  public chFavorites: PublicationTable = new PublicationTable();
  public chAll: PublicationTable = new PublicationTable();
  public evFavorites: PublicationTable = new PublicationTable();
  public evAll: PublicationTable = new PublicationTable();
  public rcFavorites: PublicationTable = new PublicationTable();
  public rcAll: PublicationTable = new PublicationTable();

  public itemsPerPage = environment.itemsPerPage;

  public filter: SearchParams = null;
  

  // tslint:disable-next-line: variable-name
  constructor( private _auth: AuthService,
               private _results: ResultsService ) {
    this.load();
  }

  load(): void {
    this.reloadChampionships();
    this.reloadEvents();
    this.reloadRaces();
  }

  reloadChampionships(): void {
    this.chLoad( 1, true );
    this.chLoad( 1 );
  }

  reloadEvents(): void {
    this.evLoad( 1, true );
    this.evLoad( 1 );
  }

  reloadRaces(): void {
    this.rcLoad( 1, true );
    this.rcLoad( 1 );
  }

  chLoad( page: number, favorite: boolean = false ): void {

    this._results.getChampionships( this.filter, page, itemsPerPage, favorite )
    .subscribe( ( championships ) => {
      // console.log( 'Championships:', championships );
      if ( favorite ) {
        this.chFavorites = championships;
      } else {
        this.chAll = championships;
      }
    },
    ( (error) => {
      console.error( 'Championships load error', error );
    }));
  }

  evLoad( page: number, favorite: boolean = false ): void {

    this._results.getEvents( this.filter, page, itemsPerPage, favorite )
    .subscribe( ( events ) => {
      // console.log( 'Events:', events );
      if ( favorite ) {
        this.evFavorites = events;
      } else {
        this.evAll = events;
      }
    });
  }

  rcLoad( page: number, favorite: boolean = false ): void {
    this._results.getRaces( this.filter, page, itemsPerPage, favorite )
    .subscribe( ( races ) => {
      // console.log( 'Races:', races );
      if ( favorite ) {
        this.rcFavorites = races;
      } else {
        this.rcAll = races;
      }
    });
  }

  search( s: SearchParams): void {
    // console.log(this.filter, s);
    this.filter = s;

    this.load();
  }

}
