import { Component, Input, OnInit } from '@angular/core';

import { PageConfig } from '../../interfaces/table-pagination.interface';
import { ResultsService } from '../../services/results.service';
import { LiveEvents, LiveEventItem } from '../../interfaces/liveEvents.interface';
import { LiveService } from '../../services/live.service';
import { UserInfo } from '../../interfaces/userInfo.interface';
import { environment } from 'src/environments/environment';

const liveUrl = environment.liveUrl;

@Component({
  selector: 'app-live-sessions',
  templateUrl: './live-sessions.component.html',
  styleUrls: [ './live-sessions.component.css',
  ]
})
export class LiveSessionsComponent implements OnInit {

  public loading: boolean = true;
  private config: PageConfig;
  public liveEvents: LiveEvents;

  // tslint:disable-next-line: variable-name
  private _club: UserInfo;

  // Component usage: clubfilter=true will restrict live session to specified club
  @Input() clubFilter: boolean = true;

  @Input() set club( c: UserInfo ) {
    this._club = c;
    this.pageLoad( 0 );
  }

  get club(): UserInfo {
    return this._club;
  }

  // tslint:disable-next-line: variable-name
  constructor( public _results: ResultsService,
               // tslint:disable-next-line: variable-name
               public _live: LiveService ) {
    this.config = {
      itemsPerPage: 20,
      currentPage: 1,
      totalItems: 0,
    };
  }

  ngOnInit(): void {
    this.pageLoad( 0 );
  }

  pageLoad( page: number ): void {

    if ( this.clubFilter ) {
      if ( this.club ) {
        this._live.getLiveUser( this.club.id )
          .subscribe( ( liveEvents: LiveEvents ) => {
            // console.log( 'Club liveEvents:', liveEvents );
            this.liveEvents = liveEvents;
          });
      }
    } else {
        this._live.getLiveList( page, 10 )
        .subscribe( ( liveEvents: LiveEvents ) => {
          // console.log( 'Global liveEvents:', liveEvents );
          this.liveEvents = liveEvents;
        });
    }

  }

  pageChanged( page: number ): void {
    this.config.currentPage = page;
    this.pageLoad( page );
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  URLLive( e: LiveEventItem ): string {
    return `${ liveUrl }/user.php?username=${ e.userName }`;
  }
}
