import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchParams } from '../../interfaces/search.interface';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: [ './search-filter.component.css' ]
})
export class SearchFilterComponent implements OnInit {

  @Input() nameHint: string;
  @Input() trackHint: string;
  @Input() trackVisible: boolean;
  @Input() raceTypeSelection: boolean;
  @Output() doSearch: EventEmitter<SearchParams> = new EventEmitter();
  
  public form: UntypedFormGroup;

  constructor( private fb: UntypedFormBuilder ) {}
  
  ngOnInit(): void {
    this.crearFormulario();
  }

  private crearFormulario(): void {
    this.form = this.fb.group({
      name: ['', []],
      track:['', []],
      from: ['', []],
      to:   ['', []],
      chFlag:   [true, []],
      evFlag:   [true, []],
      rcFlag:   [true, []],
    });
  }

  private getInputDateTime( fecha: string ): string {
    if ( fecha ) {
      return fecha.replace( ' ',  'T' );
    } else {
      return null;
    }
  }

  resetForm(): void {
    this.form.setValue({
      name: '',
      from: '',
      to: '',
      track: '',
      chFlag: true,
      evFlag: true,
      rcFlag: true,
    });
  }
  

  enviar(): void {

    if ( this.form.invalid ) {
      console.error('Form inválido ');
      // TOTDO:  Gestionar error con mensaje rojo en pantalla
      //Swal.fire( 'Error de validación', 'El formulario tiene errores', 'error' );
      return;
    }

    // console.log(this.form.value);
    const name   = this.form.value['name'];
    const from   = this.form.value['from'];
    const to     = this.form.value['to']
    const track  = this.form.value['track'];
    const chFlag = this.form.value['chFlag'];
    const evFlag = this.form.value['evFlag'];
    const rcFlag = this.form.value['rcFlag'];
    const s:SearchParams = { 
      track,
      name,
      chFlag,
      evFlag,
      rcFlag,
      from: from == '' ? null : new Date( this.getInputDateTime(from) ),
      to:   to == '' ? null : new Date( this.getInputDateTime(to) ),
    }

    this.doSearch.emit(s);
    
  }

}
