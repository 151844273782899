import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

import { UserInfo } from '../../interfaces/userInfo.interface';
import { ResultsService } from 'src/app/services/results.service';
import { PublicationTable } from 'src/app/interfaces/publication-table.interface';
import { SearchParams } from 'src/app/interfaces/search.interface';

const itemsPerPage = environment.itemsPerPage;

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styles: [
  ]
})
export class TrackComponent {

  public club: UserInfo;

  private filter: SearchParams = null;

  public chAll: PublicationTable = new PublicationTable();
  public evAll: PublicationTable = new PublicationTable();
  public rcAll: PublicationTable = new PublicationTable();

  public itemsPerPage = environment.itemsPerPage;

  constructor( private route: ActivatedRoute,
               // tslint:disable-next-line: variable-name
               private _results: ResultsService ) {
    route.params.subscribe( params => {
      this.getUserData( params.id );
    });
  }

  getUserData( userId: string): void {
    this._results.getUserInfo( +userId )
      .subscribe( u => {
        this.club = u;
        this.load();
      });
  }

  load(): void {
    this.chLoad( 1 );
    this.evLoad( 1 );
    this.rcLoad( 1 );
  }

  reloadRaces(): void {
    this.rcLoad( 1 );
  }

  chLoad( page: number ): void {

    this._results.getChampionships( this.filter, page, itemsPerPage, false, this.club.id )
    .subscribe( ( championships ) => {
      // console.log( 'Championships:', championships );
      this.chAll = championships;
    });
  }

  evLoad( page: number ): void {
    this._results.getEvents( this.filter, page, itemsPerPage, false, this.club.id )
    .subscribe( ( events ) => {
      // console.log( 'Events:', events );
      this.evAll = events;
    });
  }

  rcLoad( page: number ): void {
    this._results.getRaces( this.filter, page, itemsPerPage, false, this.club.id )
    .subscribe( ( races ) => {
      // console.log( 'Races:', races );
      this.rcAll = races;
    });
  }

  search( s: SearchParams): void {
    this.filter = s;
    console.log(this.filter);
    this.load();

  }

}
