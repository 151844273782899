import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

import { PublicationTable } from '../../interfaces/publication-table.interface';

import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
import { ResultsService } from 'src/app/services/results.service';
import { AuthService } from '../../adm/services/auth.service';

@Component({
  selector: 'app-publication-table',
  templateUrl: './publication-table.component.html',
  styleUrls: [ './publication-table.component.css',
  ]
})
export class PublicationTableComponent  {

  private dataTable: PublicationTable;
  public page: number;
  public totalItems: number;
  public loading = true;

  @Input() favorite: boolean = false;
  @Input() showTrack: boolean = true;
  @Input() itemsPerPage: number;
  @Input() object: string;
  @Input() id: string;
  @Input() showTitle: boolean = true;

  @Input() set data( info: PublicationTable) {
    this.dataTable = info;
    this.totalItems = info.paginationInfo.totalRecords;
    this.page = info.paginationInfo.page;
    this.loading = false;
  };

  get data(): PublicationTable {
    return this.dataTable;
  }


  @Output() pageLoad: EventEmitter<number> = new EventEmitter();
  @Output() onDeleteItem: EventEmitter<number> = new EventEmitter();

  private resultsUrl = environment.resultsUrl;

  userLogo( id: number ): string {
    return `${ this.resultsUrl }/api.php?rquest=userLogo&id=${ id }`;
  }

  get soyAdmin(): boolean {
    return this.authService.soyAdministrador || this.authService.soySuperAdministrador;
  }

  constructor( private translate: TranslateService,
               private resultsService: ResultsService,
               private authService: AuthService,
  ) {}

  pageChanged( page: number ): void {
    this.page = page;
    this.loading = true;
    this.pageLoad.emit( page );
  }

  getUrl( item: any ): string {

    switch( this.object ) {

      case 'event':
      case 'championship':

        return `/${ this.object  }/${ item.id }`;

      case 'race':

        if ( item.ch_id ) {
          return `/championship/${item.ch_id}/${ item.zid }`;
        } else {
          return `/event/${item.ev_id}/${ item.zid }`;
        }

      }

  }

  isOwner( userId: string ): boolean {
    return ( this.authService.soySuperAdministrador  || ( this.authService.soyAdministrador && ( this.authService.loginInfo?.id === userId )));
  }

  tryDeleteItem( item: any ): void {

    Swal.fire({
      title: this.translate.instant( "DIALOGS.confirmDeletion" ),
      html: `${ this.object } "${ item.name }" (id: ${ item.id })`,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: this.translate.instant( "DIALOGS.yes" ),
      denyButtonText: this.translate.instant( "DIALOGS.no" ),
    }).then((result) => {
      if (result.isConfirmed) {
        this.resultsService.deleteObject( this.object, item.id )
          .subscribe( 
          (resp => {
            console.log("tryDeleteItem(): ", resp);
            this.pageChanged( this.page );
            this.onDeleteItem.emit( item.id );
          }),
          (error => {
            console.error("Delete item failed ", error);
            Swal.fire({
              title: this.translate.instant( "DIALOGS.deletionError" ),
              html: `${ this.object }-${ item.id }<br>${ error.statusText }`,
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: this.translate.instant( "DIALOGS.ok" ),
            }).then((result) => {
            })
          })
        );
      }
    })
    
  }


}
