<app-live-sessions [clubFilter]="false"></app-live-sessions>

<app-search-filter [nameHint]="'FILTER.SEARCH_RACE_HINT'" [trackHint]="'FILTER.SEARCH_TRACK_HINT'" [trackVisible]="true" [raceTypeSelection]="true" (doSearch)="search( $event )"/>

<div *ngIf="!filter || filter.chFlag ">
  <div class="row">
    <div class="col">
      <h5 class="section" [attr.id]="'championships'">{{ 'HOME.CHAMPIONSHIPS' | translate }}</h5>
    </div>	
  </div>

  <div class="row">
    <div class="col-lg-5">
      <app-publication-table id="chFav" [favorite]=true [object]="'championship'" [itemsPerPage]="itemsPerPage" (onDeleteItem)="reloadRaces()" (pageLoad)="chLoad( $event, true )"  [data]="chFavorites"></app-publication-table>
    </div>
    <div class="col-lg-5 offset-sm-1">
      <app-publication-table id="chAll" [favorite]=false [object]="'championship'" [itemsPerPage]="itemsPerPage" (onDeleteItem)="reloadRaces()" (pageLoad)="chLoad( $event, false )" [data]="chAll"></app-publication-table>
    </div>
  </div>
</div>

<div *ngIf="!filter || filter.evFlag ">
  <div class="row mt-5">
    <div class="col">
      <h5 class="section" [attr.id]="'events'">{{ 'HOME.EVENTS' | translate }}</h5>
    </div>	
  </div>

  <div class="row">
    <div class="col-lg-5">
      <app-publication-table id="evFav" [favorite]=true [object]="'event'" [itemsPerPage]="itemsPerPage" (onDeleteItem)="reloadRaces()" (pageLoad)="evLoad( $event, true )" [data]="evFavorites"></app-publication-table>
    </div>
    <div class="col-lg-5 offset-sm-1">
      <app-publication-table id="evAll" [favorite]=false [object]="'event'" [itemsPerPage]="itemsPerPage" (onDeleteItem)="reloadRaces()" (pageLoad)="evLoad( $event, false )" [data]="evAll"></app-publication-table>
    </div>
  </div>
</div>

<div *ngIf="!filter || filter.rcFlag ">
  <div class="row mt-5">
    <div class="col">
      <h5 class="section" [attr.id]="'races'">{{ 'HOME.RACES' | translate }}</h5>
    </div>	
  </div>

  <div class="row pb-50">
    <div class="col-lg-5">
      <app-publication-table id="rcFav" [favorite]=true [object]="'race'" [itemsPerPage]="itemsPerPage" (pageLoad)="rcLoad( $event, true )" [data]="rcFavorites"></app-publication-table>
    </div>
    <div class="col-lg-5 offset-sm-1">
      <app-publication-table id="rcAll" [favorite]=false [object]="'race'" [itemsPerPage]="itemsPerPage" (pageLoad)="rcLoad( $event, false )" [data]="rcAll"></app-publication-table>
    </div>
  </div>
</div>
