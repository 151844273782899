<div [ngClass]="{ 'favoritos' : favorite, 'titulo_lista': !favorite }">
  <i *ngIf="favorite" class="fa fa-heart"></i>
  <span *ngIf="showTitle" style="margin: 0px 5px;">{{ (favorite ? 'HOME.HOTTEST' : 'HOME.ALL') | translate }}</span>
</div>

<div *ngIf="data.items.length === 0" class="col animated fadeIn fast">
    <div class="col">

        <div class="alert alert-info text-center" role="alert">
          <h5 class="alert-heading"></h5>
          <i class="fa fa-ban fa-2x"></i>
          <p class="mb-0">{{ 'HOME.NO_RESULTS' | translate }}</p>
        </div>
    </div>
</div>

<div *ngIf="data.items.length > 0" class="col animated fadeIn fast">
  <table class="table table-striped table-sm" style="font-size: 0.8em;">
    <thead class="thead-dark">
      <tr>
        <th class="date-header">{{ 'HOME.PUBLICATION_TABLE.Date' | translate}}</th>
        <th class="name-header">{{ 'HOME.PUBLICATION_TABLE.Description' | translate}}</th>
        <th>{{ 'HOME.PUBLICATION_TABLE.Club' | translate}}</th>
        <th class="class-header d-none d-lg-table-cell">{{ 'HOME.PUBLICATION_TABLE.Class' | translate}}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="animated fadeIn fast" *ngFor="let item of data.items | paginate: { currentPage: page, itemsPerPage: itemsPerPage, totalItems: totalItems, id: id }">
        <td><span class="date-column">{{ item.date | date: 'y-MM-dd' }}</span></td>
        <td><a class="pointer" [routerLink]="getUrl(item)">{{ item.name }}</a></td>
        <td><a class="pointer" [routerLink]="'/track/'+item.userId"><img class="avatar" [src]="userLogo( item.userId )"></a></td>
        <td class="d-none d-lg-table-cell">{{ item.class }}</td>
        <td class="text-center" *ngIf="isOwner( item.userId )"><a class="pointer delete" (click)="tryDeleteItem( item )"><i class="fas fa-times"></i></a></td>
        <td *ngIf="!isOwner( item.userId )"></td>
      </tr>
    </tbody>
  </table>
  <pagination-controls [id]="id" *ngIf="data.items.length < data.paginationInfo.totalRecords" (pageChange)="pageChanged($event)"></pagination-controls>
</div>
