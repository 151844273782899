<div *ngIf="liveEvents && liveEvents.events.length > 0">
  <div class="d-flex justify-content-center">
      <div class="roller">
          <h3>
            <span class="mr-5" *ngFor="let i of numSequence(2)">{{ 'RESULTS.LIVE' | translate }}</span>
          </h3>
      </div>	
  </div>
  <div class="d-flex justify-content-center">
    <div style="width: 40%">
      <table id="tb_live" class="table table-sm table-striped" style="font-size: 0.8em;">
        <thead class="thead-dark">
          <tr>
            <th></th>
            <th>Race track</th>
            <th>Session</th>
            <th>Watching</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let event of liveEvents.eventList">
            <td><a class="pointer" [routerLink]="'/track/'+event.userId"><img class="avatar" [src]="event.logo"></a></td>
            <td><a class="pointer" target="_blank" rel="noopener noreferrer" [href]="URLLive(event)">{{ event.displayName }} <i class="fa-solid fa-wifi"></i></a></td>
            <td>{{ event.sessionName }}</td>
            <td>{{ event.connectedUsers }}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls *ngIf="liveEvents.events.length > 10" (pageChange)="pageChanged($event)"></pagination-controls>
    </div>
  </div>
</div>