// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logo: './assets/img/zround_logo_dev.png',
  liveApiUrl: 'https://dev-live.zround.com:9082',
  resultsUrl: 'https://dev-results.zround.com/api',
  clientId:   '3ed7c5f5-cf88-41f7-925d-dee495c4d830',
  liveUrl:    'https://dev-live.zround.com',
  clientSecret: 'V3gaY-^jVcFKXJ&pW%kpaD_^qc7kENcGzu&V^d!L',
  itemsPerPage: 10,
/*
  liveApiUrl: 'https://live.zround.com:7082',
  resultsUrl: 'https://results.zround.com/api',
  clientId: '471d3998-a18f-46a4-935f-f25d1121e529',
  clientSecret: '*BBx$5uR459$z9a*NGVF26akakYwvsyvd*vqyu!7',
*/  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
