<div class="container-fluid mt-2" *ngIf="usuario">
    <div class="row">

        <div class="col col-lg-7">

            <div class="card">
                <div class="card-body">
                    <h3 class="card-title">{{ !usuario.id ? 'ACTIONS.create' : 'ACTIONS.update' | translate }} {{ 'USERS.user' | translate }}</h3>
                    <h6 class="card-subtitle">{{ 'USERS.profile' | translate }}</h6>

                    <form autocomplete="on" [formGroup]="form" (ngSubmit)="enviar()" class="form-horizontal p-t-20">

                        <div class="form-group row">
                            <label for="id" class="col-sm-3 control-label">Id</label>
                            <div class="col-sm-2 col-xl-4">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-key"></i></div>
                                    <input type="text" class="form-control" formControlName="id" id="id" readonly>
                                </div>
                            </div>
                            <small class="control-label text-secondary m-l-5" *ngIf="usuario && usuario.id">
                                <i>{{ 'ACTIONS.created' | translate }} {{ usuario.created_on | date:'dd/MM/yy' }}</i>
                            </small>
                        </div>

                        <div class="form-group row">
                            <label for="login" class="col-sm-3 control-label">{{ 'USERS.login' | translate }}</label>
                            <div class="col-sm-4 col-xl-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-user"></i></div>
                                    <input type="text" class="form-control" formControlName="login" id="login" placeholder="Cuenta de usuario" readonly>
                                    </div>
                            </div>
                        </div>
                        
                        <div *ngIf="soyAdmin" class="form-group row">
                            <label for="rol" class="col-sm-3 control-label">{{ 'USERS.role' | translate }}</label>
                            <div class="col-sm-4 col-xl-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-crown"></i></div>
                                    <select class="form-control" formControlName="role">
                                        <option value="">{{ 'USERS.roleSelection' | translate }}</option>
                                        <option *ngFor="let rol of roles" [value]="rol">{{ rol }}</option>
                                    </select>
                                    <small *ngIf="rolNoValido" class="text-danger">
                                        {{ 'USERS.error.roleMandatory' | translate}}
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="email" class="col-sm-3 control-label">{{ 'USERS.email' | translate }}</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-email"></i></div>
                                    <input type="text" class="form-control" formControlName="user_email" id="email" placeholder="email registrado" readonly>
                                    </div>
                            </div>        
                        </div>    

                        <div class="form-group row">
                            <label for="display_name" class="col-sm-3 control-label">{{ 'USERS.displayName' | translate }}</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-info-alt"></i></div>
                                    <input type="text" class="form-control" formControlName="display_name" id="display_name" placeholder="Nombre a mostrar o descripción">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="display_name" class="col-sm-3 control-label">{{ 'USERS.web' | translate }}</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-world"></i></div>
                                    <input type="url" class="form-control" formControlName="www" id="www" placeholder="Web del usuario">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="display_name" class="col-sm-3 control-label">{{ 'USERS.subtitle' | translate }}</label>
                            <div class="col-sm-9 col-xl">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-info"></i></div>
                                    <input type="text" class="form-control" formControlName="slogan" id="slogan" placeholder="Subtítulo">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="disk_mb_quota" class="col-sm-3 control-label">{{ 'USERS.diskQuota' | translate }}</label>
                            <div class="col-sm-2 col-xl-4">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-server"></i></div>
                                    <input type="text" [attr.readonly]="!soyAdmin ? true : null"  class="form-control" formControlName="disk_mb_quota" id="disk_mb_quota">
                                </div>
                            </div>
                            <small class="control-label text-secondary m-l-5">
                                <i>{{ diskUsage | number:'1.1-1'}}%</i>
                            </small>
                        </div>

                        <div class="form-group row">
                            <label for="auth_key" class="col-sm-3 control-label">{{ 'USERS.diskSpace' | translate }}</label>
                            <div class="col col-sm-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-server"></i></div>
                                    <input readonly type="text" class="form-control" formControlName="disk_mb_size" id="disk_mb_size">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="auth_key" class="col-sm-3 control-label">{{ 'USERS.authKey' | translate }}</label>
                            <div class="col col-sm-5">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-lock"></i></div>
                                    <input type="text" class="form-control" formControlName="auth_key" id="auth_key" placeholder="Código de autorización">
                                    <button type="button" class="btn btn-outline-secondary ti-reload" (click)="regeneraAuth()"></button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row mt-2">
                            <div class="offset-sm-3 col-sm-9">
                                <div class="checkbox checkbox-success">
                                    <input id="activo" type="checkbox" formControlName="active">
                                    <label for="activo" class="control-label">
                                        {{ 'USERS.activeAccount' | translate }} 
                                        <a *ngIf="activeAccount && ( id != '' )" class="ml-3" _target="blank" [routerLink]="'/track/' + id">
                                            <i class="fa-solid fa-globe"></i>
                                        </a>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col">
                                <h3>{{ 'USERS.liveService' | translate }}</h3>
                            </div>
                        </div>
                        <div class="form-group row">                        
                            <label for="live_start_date" class="col-sm-4 col-lg-3 col-xl-4 control-label">{{ 'USERS.startDate' | translate }}</label>
                            <div class="col col-sm-5 col-xl-6">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-face-smile"></i></div>
                                    <input type="datetime-local" class="form-control" formControlName="live_start_date" id="live_start_date">
                                </div>
                            </div>
                        </div>
                                
                        <div class="form-group row">
                            <label for="live_expiration" class="col-sm-4 col-lg-3 col-xl-4 control-label">{{ 'USERS.expirationDate' | translate }}</label>
                            <div class="col col-sm-5 col-xl-6">
                                <div class="input-group">
                                    <div class="input-group-addon"><i class="ti-face-sad"></i></div>
                                    <input type="datetime-local" class="form-control" formControlName="live_expiration" id="live_expiration">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col offset-sm-4 offset-lg-3 offset-xl-4 mt-2">
                                <div class="checkbox checkbox-success">
                                    <input id="live_enabled" type="checkbox" formControlName="liveEnabled">
                                    <label for="live_enabled" class="control-label">{{ 'USERS.activeService' | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row m-b-0 mt-3">
                            <div class="offset-sm-2 col-sm-9">
                                <button type="submit" class="btn btn-success btn-sm waves-effect waves-light" (submit)="enviar()"><i class="ti-save"></i> {{ 'ACTIONS.save' | translate }}</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>

        <div class="col col-lg-5">
            <div class="card" *ngIf="usuario">
                <div class="card-body">
                    <div class="form-group row">
                        <div class="col-sm-2">

                            <img class="w-200 pointer"
                                *ngIf="!imgTemp"
                                [alt]="usuario.display_name"
                                [src]="usuario.img | img">

                            <img *ngIf="imgTemp"
                                [src]="imgTemp"
                                class="w-200">

                            <input  class=" mt-2" type="file"
                                    (change)="cambiarImagen( $event.target.files[0] )">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    
