import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserInfo } from '../../interfaces/userInfo.interface';
import { ResultsService } from 'src/app/services/results.service';

@Component({
  selector: 'app-practices',
  templateUrl: './practices.component.html',
  styleUrls: ['./practices.component.css']
})
export class PracticesComponent {

  public club: UserInfo;

  constructor( private activatedRoute: ActivatedRoute,
               private _results: ResultsService ) {
    this.activatedRoute.params.subscribe( params => {
      this.getUserData( params.id );
    });
  }

  getUserData( userId: string): void {
    this._results.getUserInfo( +userId )
      .subscribe( u => {
        this.club = u;
        this.load();
      });
  }

  // TODO: Practice landing page loading
  load(): void {
    
  }

}
