<script>  
    function pop(url, titulo)
    {
      my_window = window.open(url, titulo, "status=1,width=450,height=250");

    }
</script>

<footer class="footer container-fluid hidden-print">
    <div class="d-flex">

        <div class="align-self-center">
            <!-- Button HTML (to Trigger Modal) -->
            <button type="button" class="btn btn-outline-warning ml-2" data-toggle="modal" data-target="#legalNotice">
                {{ 'HOME.LEGAL'  | translate }}
            </button>
        </div>

        <div class="align-self-center version"><small>Version {{ SOFT_VERSION.tag }} - {{ SOFT_VERSION.hash }}</small></div>

        <div class="ml-auto">
            <!-- <a style="color: #55acee;" href="https://x.com/ZRound_News" target="_blank"><i class="fa-brands fa-x-twitter"></i></a> -->
            <a style="color: #8b9dc3; margin-left: 5px;" href="https://www.facebook.com/zround/" target="_blank"><i class="fa fa-facebook-square"></i></a>
            <a style="color: #ff0000; margin-left: 5px;" href="https://www.youtube.com/zround/" target="_blank"><i class="fa fa-youtube"></i></a>
            <span class="copyright">{{ CopyRight }}</span>
        </div>
    </div>
</footer>

<!-- Modal -->
<div class="modal fade" id="legalNotice" tabindex="-1" role="dialog" aria-labelledby="modalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
    <div class="modal-content" style="height: 800px; width: 600px; top: 50px; overflow-y: scroll">
        <div class="modal-header">
        <h5 class="modal-title" id="modalTitle">ZRound - {{ 'HOME.LEGAL' | translate }} </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body mb-0 p-0">
            <div class="embed-responsive z-depth-1-half" style="height:100%">
                <iframe class="embed-responsive-item" src="/assets/legal_notice.html"></iframe>
            </div>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ 'HOME.CLOSE' | translate }}</button>
        </div>
    </div>
    </div>
</div>
