import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdmRoutingModule } from './adm/adm.routing';

import { HomeComponent } from './pages/home/home.component';
import { ChampionshipComponent } from './pages/championship/championship.component';
import { EventComponent } from './pages/event/event.component';
import { TrackComponent } from './pages/track/track.component';
import { PracticesComponent } from './pages/practices/practices.component';

const routes: Routes = [
  // path: '/adm' AdmRouting
  { path: 'home',          component: HomeComponent },
  { path: 'championship/:id',  component: ChampionshipComponent },
  { path: 'championship/:id/:raceid',  component: ChampionshipComponent },
  { path: 'event/:id',  component: EventComponent },
  { path: 'event/:id/:raceid',  component: EventComponent },
  { path: 'track/:id',  component: TrackComponent },
  { path: 'practices/:id',  component: PracticesComponent },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 25],
    }),
    AdmRoutingModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
